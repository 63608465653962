
.box {
  border: 1px solid #ddd;
  padding: 1.5em;

  display: inline-block;
  width: 400px;
}
.box form label {
  text-align: left;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
.box form label > input {
  display: block;
  width: 100%;
  margin-bottom: 10px;

  line-height: 32px;
  padding: 0 .5em;
  font-weight: normal;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.box form s.info {
    font-size: 12px;
    color: #555;
    text-decoration: none;
    line-height: 19px;
    width: 100%;
    text-align: right;
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
}

.box form label > input[disabled] {
    background-color: #f7f7f7;
}

.box form .form-actions {
  text-align: right
}
.box form .form-actions button {
  text-align: center;
  background: #07C;
  color: #FFF;
  border-radius: 3px;
  border: none;
  padding: 10px 40px;
}
.box form .form-actions button[disabled] {
    opacity: .5;
}

.box-container {
  text-align: center;
}


.login-message {
    width: 400px;
    text-align: right;
    display: inline-block;
    font-size: 12px;
}