.config-form .users {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border: 1px solid #ccc;

    padding: 7px 0 0 7px;
    background-color: #f7f7f7;

    overflow-y: auto;
    overflow-x: hidden;

    max-height: 230px;
}
.config-form .users > li {
    display: inline-block;
    min-width: 30%;
    max-width: 33.334%;
    border: 1px solid #ccc;
    background-color: #fff;

    margin: 0 7px 7px 0;

    flex-grow: 1;
    flex-basis: 0;
    padding: 5px 10px;

    font-size: 12px;
}

.config-form .users > li > b {
    display: block;
}
.config-form .users > li > s {
    display: block;
    text-decoration: none;

    color: #555;
}
.config-form .users > li > i {
    display: block;
    color: #555;
}

.config-form .add-users {
    display: flex;
    margin-top: 10px;
}

.config-form .add-users textarea {
    flex-grow: 1;
    flex-basis: 0;
    border: 1px solid #ccc;
    resize: none;
    height: 75px;
    padding: 5px 10px;
}
.config-form .btn {
    margin: 0 0 0 10px;
    border: 0;
    height: 32px;
}

.config-form .react-tabs__tab-list {
    margin: 0;
}
.react-tabs__tab-panel {
    padding: 10px;
    border: 1px solid #aaa;
    border-top: none;
}


.config-form .form-control {
    margin-bottom: .5em;
}
.config-form .form-control > label {
    display: block;
    font-size: 13px;
    font-weight: bold;
}
.config-form .form-control > input,
.config-form .form-control > textarea {
    border: 1px solid #ccc;
    padding: 4px 10px;
    font-size: 14px;
    width: 100%;

    min-height: 27px;
    resize: vertical;
}

.config-form .checkbox {
    margin-bottom: .5em;
}
.config-form .checkbox > label {
    font-size: 14px;
    line-height: 20px;
    margin-left: .5em;
}


.config-form .columns {
    display: flex;
    flex-direction: row;
}
.config-form .columns > div {
    flex-grow: 1;
    flex-basis: 0;

    margin-left: 10px;
}
.config-form .columns > div:first-of-type {
    margin-left: 0;
}

.config-form .columns h3 { 
    margin: 0 0 .5em;
}

.config-form .actions > .btn:first-of-type {
    margin-left: 0;
}

.config-form .actions > .btn:disabled {
    opacity: .5;
}
