body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}
