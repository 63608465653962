.profile-form dl {
  display: flex;
}

.profile-form dl dd {
  line-height: 180px;
  margin: 0;
}
.profile-form dl > dd.separator {
  display: none;
}
.profile-form dl dd * {
  line-height: normal;
}
.profile-form dl dd > div.vcenter {
  display: inline-block;
  margin: 0 5em;
}

.profile-form .form-check {
  line-height: normal;
}

.profile-points {
  background: #138613;
  background: radial-gradient(#138613, #136013);
  color: #fff;
  border-radius: 5px;
  width: 215px;

  text-align: center;
  line-height: normal;
  padding-bottom: 1.2em;

  margin-right: 1.2em;
}
.profile-points > b {
  display: block;
  font-size: 72px;
  font-weight: bold;

  line-height: 100px;
}
.profile-points > s {
  text-decoration: none;
}

.profile-form .user-info {
  flex-grow: 1;
  flex-basis: 0;
}
.profile-form .user-info > ul {
  line-height: 1.2;
  list-style: none;
  margin: 1em 0;
  padding: 0;
}

/*.profile-form*/
.btn {
  text-decoration: none;
  color: #fff;
  background-color: #017ab2;

  display: inline-block;
  padding: 3px 15px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0.75em 1.2em 0 0;
}

/*.profile-form*/
.btn.btn-back:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-image: url(../images/img-back.png);
  background-size: contain;
  margin-right: 0.5em;
}
