.home-page {
    text-align: justify;
    color: #222;
}

.home-page .c {
    text-align: center;
}

.home-page a.btn {
    display: inline-block;
    background-color: #017ab2;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: .85;
    margin: .75em .6em;

    transition: all .15s ease-in-out;
}
.home-page a.btn:hover {
    opacity: 1;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.31);
}
