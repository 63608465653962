@media only screen and (max-width: 1790px) {
  .App > .main-content > div {
    display: flex;
    flex-direction: row-reverse;
  }
  .main-content > div aside.side-bar {
    position: static;
    margin-left: 15px;
  }

  .main-content > div > .container {
    max-width: unset;
    margin-left: 7px;
  }
}

@media only screen and (max-width: 980px) {
  .App > .main-content > div {
    display: flex;
    flex-direction: column-reverse;
  }
  .main-content > div > .container {
    margin: 0;
    padding: 0 7px;
  }
  .main-content > div aside.side-bar {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .main-content > div aside.side-bar .sticky-bar {
    padding-left: 7px;
  }
}

@media only screen and (max-width: 740px) {
  .App-logo {
    height: 75px;
  }

  .match {
    font-size: 12px;
  }
  .match .guess {
    font-size: 14px;
    line-height: 20px;
  }
  .match-group .flag {
    height: 20px;
  }
  .match > .team > span {
    line-height: 20px;
  }

  .panel .points {
    width: 32px;
    height: 32px;
  }
  .panel .points > b {
    font-size: 14px;
    line-height: 16px;
  }
  .panel .points > s {
    font-size: 7px;
  }

  .profile-form {
    margin-bottom: 20px;
  }
  .profile-form dl {
    display: block;
  }
  .profile-form dl > dd {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 15px;
  }
  .profile-points {
    width: 155px;
  }
  .profile-form dl > dd > img {
    margin-right: 1.2em;
  }

  .profile-form dl dd > div.vcenter {
    margin: 0;
  }

  .profile-form dl > dd.separator {
    display: block;
  }

  /*
    .profile-form dl > dd:nth-child(odd) {
        width: 33%;
    }
    .profile-form dl > dd:nth-child(even) {
        width: 66%;
    }
    */

  .match .result > div {
    font-size: 9px;
  }
}

@media only screen and (max-width: 615px) {
  .App-header {
    padding-bottom: 52px;
  }

  .App-intro .user-info {
    top: -48px;
    right: 3px;
  }

  .match > .team {
    position: relative;
  }
  .match-group .flag {
    position: absolute;
    top: 20px;
  }

  .match > .panel {
    width: 80px;
  }
  .match > .panel.panelr {
    width: 45px;
  }

  .match > .team.home .flag {
    right: 36px;
  }
  .match > .team.away .flag {
    left: 36px;
  }

  .panel .points {
    top: 10px;
  }

  .box-container > .box {
    width: 100%;
  }

  .match .result > div {
    margin-left: 35px;
  }
}

@media only screen and (max-width: 440px) {
  .App-intro,
  .App-footer {
    font-size: 13px;
  }
  .match > .team > span {
    font-size: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;

    display: inline-block;
    max-width: 70px;
    overflow: hidden;
  }
  .match > .team {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 40px;
  }

  .group-title {
    font-size: 13px;
  }
}
@media only screen and (max-width: 400px) {
  .match > .team > span {
    max-width: 45px;
  }
}

@media only screen and (min-width: 1100px) {
  .App-header {
    background: url(./images/fundo-2022-large.jpg);
    background-size: cover;
  }
  .App-header > .container {
    background: none;
  }
}
