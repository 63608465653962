.group-title,
.guess-actions {
  color: #666;
  background: #eee;

  font-weight: 500;
  font-size: 16px;

  line-height: 1.4;
  padding: 0.5em 1.5em;
  margin: 0;

  border-radius: 3px;

  text-align: left;

  position: sticky;
  top: -1px;
  z-index: 1000;
}

.group-title.ranking {
  padding-right: 7px;
}

.match-group .group-title {
  top: 36px;
}

.guess-actions {
  background-color: #0f3f79;
  color: #fff;
  top: -1px;
  z-index: 1001;
  height: 38px;
  padding: 0.5em;
}
button.bt {
  color: #fff;
  border: 0;
  background: #1355a0;
  border-radius: 4px;
  padding: 2px 10px;
  font-weight: bold;

  cursor: pointer;

  margin-right: 4px;
  min-width: 32px;
}
button.bt:hover {
  background: #1561b7;
}
button.bt.pull-right {
  float: right;
  margin-right: 0;
}
button.bt.dt {
  background-image: url(../../images/btn_cal_s.png);
  background-repeat: no-repeat;
  background-position: center center;
}
button.bt.grp {
  background-image: url(../../images/btn_grp_s.png);
  background-repeat: no-repeat;
  background-position: center center;
}
button.bt.active {
  background-color: #186dcd;
  cursor: default;
  outline: none;
}
button.bt:disabled {
  opacity: 0.5;
  cursor: default;
}

button.bt.grey {
  background-color: #595959;
}
button.bt.grey:hover {
  background-color: #666;
}

.match-group .flag {
  height: 32px;
  border-radius: 3px;
  margin: 0 0.5em;

  border: 1px solid #eee;
}

.match {
  position: relative;
  display: flex;
  flex-direction: row;

  border-top: 1px solid #eee;
  padding: 0.7em 0;

  user-select: none;
  cursor: default;
}
.match:first-of-type {
  border-top: 0;
}
.match > .team {
  flex-grow: 1;
  flex-basis: 0;
}
.match > .team.home {
  text-align: right;
}
.match > .team.away {
  text-align: left;
}

.match > .team.home .flag {
  float: right;
}
.match > .team.away .flag {
  float: left;
}

.match > .team > span {
  line-height: 32px;
}

.match > .panel {
  font-size: 0.7em;
  text-align: left;
  width: 120px;
  padding: 0 1em;
}

.match > .panel i {
  opacity: 0.7;
}

.match .guess {
  width: 2.1em;
  line-height: 32px;
  margin: 0 0.5em;
  padding: 0;
  text-align: center;
  font-weight: bold;
  font-size: 16px;

  border: 1px solid #ccc;
  border-radius: 3px;

  float: left;
}
.match > .team.home .guess {
  float: right;
}

.panel .points {
  position: absolute;
  right: 7px;
  top: 3px;
  display: flex;
  flex-direction: column;

  width: 48px;
  height: 48px;
  border: 1px solid #138613;
  background: #138613;
  border-radius: 5px;

  text-align: center;
  color: #fff;
}

.panel .points > b {
  flex-grow: 1;
  flex-basis: 0;
  line-height: 30px;
  font-size: 24px;
}
.panel .points > s {
  text-decoration: none;
  padding-bottom: 2px;

  background: rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  font-size: 9px;
  padding-top: 3px;
}

.match .result {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 2px;
}
.match .result > div {
  display: inline-block;
  background: #0f3f79;
  color: #fff;
  padding: 2px 12px;
  border-radius: 7px;

  font-size: 11px;
  font-weight: bold;
}
