
aside.side-bar .group-title {
  border-radius: 3px 3px 0 0;
}
aside.side-bar ul {
  border: 1px solid #eee;
  margin: 0 0 1em 0;
  list-style: none;
  padding: 0;

  text-align: left;
  font-size: 12px;
}

aside.side-bar ul li {
  padding: .3em 1.3em;
}

aside.side-bar ul li b {
  float: right;
  color: #017ab2;
}

aside.side-bar ul.comments {
  margin: 0;
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
}
ul.comments i {
  display: block;
  font-size: .8em;
  text-align: right;
  border-bottom: 1px solid #eee;
  padding-bottom: 3px;
}

.comment-form {
  border: 1px solid #eee;
  border-top: 0;
  display: flex;
}
.comment-form > textarea,
.comment-form > input {
  flex-grow: 1;
  flex-basis: 0;
  resize: none;
  border: 0;

  font-size: 12px;
  height: 3em;
}
.comment-form > div {
  padding: 2px 2px 2px 5px;
}
.comment-form > div > button {
  border-radius: 7px;
  border: 0;
  background: #017ab2;
  color: #fff;
  font-size: 12px;
  height: 100%;

  cursor: pointer;
}

.comment-form > div > button:hover {
  background: #018bcb;
}

.comment-form > div > button:disabled {
    background-color: #55A6CB !important;
    cursor: default;
}

