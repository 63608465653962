.user-ranking {
    list-style: none;
    padding: 0;
    margin: 0;
}

.user-ranking > li {
    padding: 0 1.5em;
    line-height: 48px;
    border-bottom: 1px solid #eee;
}

.user-ranking b {
    float: right;
    color: #017ab2;
}

.user-ranking i {
    display: inline-block;
    width: 40px;
    text-align: right;
    padding-right: 6px;
    font-style: normal;
    font-weight: bold;
    color: #555;
}
