html,
body,
#root {
  height: 100%;
}

.App {
  text-align: center;

  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.App > .main-content {
  flex-grow: 1;
  flex-basis: 0;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 150px;
}

.App-header {
  /* background-color: #600620;
  background: linear-gradient(#600620, #7d0d29); */

  color: white;

  background-position: 0 -2px;
  background-color: #e2a51b;
}

.App-header > .container {
  background: url(./images/fundo-2022.jpg);
  background-size: cover;
  padding: 20px;
  padding-right: 0;
}

.App-header .main-logos {
  display: inline-flex;
  align-items: center;
  column-gap: 70px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
  position: relative;
  text-align: center;

  line-height: 60px;
}

.App-footer {
  background: #222;
  color: #fff;
  padding: 1em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1200px;
  width: 100%;
  display: inline-block;
  text-align: left;
}

.App-logo.r {
  float: right;
}

.main-content {
  position: relative;
}
.main-content aside.side-bar {
  position: absolute;
  right: 0;
  top: 0;
  width: 275px;
  height: 100%;

  padding-right: 7px;
}

.sticky-bar {
  position: sticky;
  top: -1px;
}

.App-intro .user-info {
  position: absolute;
  top: 7px;
  right: 0;

  line-height: 18px;
}

.App-intro .user-info > .user {
  border: 1px solid #aaa;
  background: #fff;
  color: #666;
  padding: 3px 10px;
  text-align: right;
  font-size: 13px;
}

.App-intro .user-info > .user a {
  text-decoration: none;
  margin-left: 7px;
  font-size: 12px;
  font-weight: bold;
}

.ranking-list li {
  padding-left: 0.5em !important;
}
.ranking-list i {
  display: inline-block;
  width: 40px;
  text-align: right;
  padding-right: 6px;
  font-style: normal;
  font-weight: bold;
  color: #555;
}

.hidden {
  display: none !important;
}
